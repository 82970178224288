jQuery(document).ready( function() {
  //Add active class to the top navigation
  jQuery('#poe_top_nav a, .hamMenuLinkSecond').filter(function(){
    return this.href === location.href;
  }).addClass('poe_active_top_nav');
  //Add active class to the main navigation
  jQuery('#poe_main_nav a.nav-link, .hamMenuInnerWrap .hamMenuLinkMain').filter(function(){
    return this.href === location.href;
  }).addClass('poe_active_main_nav');
  //Hamburger menu opener
  jQuery(".hamburger").on("click touch", function() {
    jQuery(".hamburger").toggleClass("is-active");
    jQuery(".hamMenuBlock").removeClass("hamMenuStart");
    jQuery(".hamMenuBlock").toggleClass("hamMenuBlockOpen");
    jQuery(".hamMenuBlock").toggleClass("hamMenuBlockClose");
    jQuery("body").toggleClass("scrollStopper");
    if (jQuery(".hamMenuBlock").hasClass("hamMenuBlockClose")) {
      jQuery(".hamMenuBlock").delay(700).queue(function(next){
        jQuery(".hamMenuBlock").addClass("hamMenuStart");;
        next();
      });
    }
  });
  jQuery("#poe_footer_cta_form .wpcf7-submit").on("click touch", function() {
    setTimeout(function() {
      jQuery("#poe_footer_cta_form .wpcf7-text").val("");}, 4800);
  });
});
